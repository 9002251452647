(function () {
    angular.module('informaApp')
        .component('datasetDialog', {
            templateUrl: 'components/home/dataset-dialog/template.html',
            controller: ['$element', 'ModalHelper', DatasetDialogComponent],
            bindings: {
                dialogId: '@',
                onDownload: '<'
            }
        });

    function DatasetDialogComponent($element, ModalHelper) {
        var vm = this;

        vm.exportOptions = {
            inverted: true,
            model: true
        };

        vm.cancel = function (callback) {
            ModalHelper.hideModalByElement($element.find('.modal'), callback);
        }

        vm.download = function () {
            vm.cancel(function () {
                if (vm.onDownload) {
                    vm.onDownload(vm.exportOptions.model);
                }
            });
        }
    }
})();